import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import { causten_font } from '@styles/typography';
import { color_blue } from '@styles/colors';

export const LCCategoryContainer = styled.div`
  font-family: ${causten_font};
`;

export const Header = styled.div`
  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(20)};
  }
`;

export const Heading = styled.h1`
  display: none;
  ${mq_min('lg')} {
    font-weight: 400;
    font-size: ${getStyleSizes(50)};
    letter-spacing: 0.01em;
    display: block;
  }
`;
export const CategoryTag = styled.div`
  color: ${color_blue};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;
