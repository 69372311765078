import React from 'react';
import {
  Date,
  LandingPageBannerContainer,
  Image,
  Picture,
  TextContent,
  Heading,
  Content,
} from '@shared/components/landing-page-banner/landing-page-banner.styles';
import { CategoryTag } from '@cms-components/learning-center/lc-category/lc-category.styles';

import {
  ArticleBannerProps,
  LandingPageBannerMainProps,
  LandingPageBannerProps,
} from '@shared/components/landing-page-banner/landing-page-banner.interface';
import { RichText } from '@shared/components';
import BasicLink from '@shared/components/basic-link';

const ArticleBanner = ({
  firstArticle,
  urlSegment,
  fallbackImage,
}: ArticleBannerProps) => {
  if (!firstArticle) {
    return <></>;
  }
  const isArticle = true;
  const { topics, headline, publication_date, featured_image, page_url, uid } =
    firstArticle;
  const itemImage = featured_image.image.image
    ? featured_image.image
    : fallbackImage;
  return (
    <LandingPageBannerContainer id={uid}>
      <TextContent isArticle={isArticle}>
        {topics && (
          <CategoryTag>
            {topics.map((topic) => topic.display_name).join(', ')}
          </CategoryTag>
        )}
        <Heading>
          <BasicLink text={headline} url={page_url || ''} />
        </Heading>
        {urlSegment?.indexOf('blogs') !== -1 && publication_date && (
          <Date>{publication_date}</Date>
        )}
      </TextContent>
      <Picture isArticle={isArticle}>
        <Image
          image={itemImage.image.gatsbyImageData}
          alt_text={itemImage.image_alt_text || ''}
        />
      </Picture>
    </LandingPageBannerContainer>
  );
};

const LandingBanner = ({
  landingBanner,
  fallbackImage,
}: LandingPageBannerProps) => {
  if (!landingBanner) {
    return <></>;
  }
  const { eyebrow, headline, content, image, uid } = landingBanner || {};
  const { image: bannerImage, image_alt_text: bannerImageAltText } =
    image || {};
  const isArticle = false;
  return (
    <LandingPageBannerContainer id={uid || content.uid}>
      <TextContent isArticle={isArticle}>
        {eyebrow && <CategoryTag>{eyebrow}</CategoryTag>}
        <Heading>{headline}</Heading>
        <Content>
          <RichText content={content} />
        </Content>
      </TextContent>

      <Picture isArticle={isArticle}>
        <Image
          image={
            bannerImage?.gatsbyImageData || fallbackImage?.image?.gatsbyImageData
          }
          fallback={bannerImage?.url}
          alt_text={bannerImageAltText || fallbackImage?.image_alt_text || ''}
        />
      </Picture>
    </LandingPageBannerContainer>
  );
};

export const LandingPageBanner = ({
  landingBanner,
  firstArticle,
  fallbackImage,
  urlSegment,
}: LandingPageBannerMainProps) => {
  if (firstArticle) {
    return (
      <ArticleBanner
        firstArticle={firstArticle}
        fallbackImage={fallbackImage}
        urlSegment={urlSegment}
      />
    );
  }

  if (landingBanner) {
    return (
      <LandingBanner
        landingBanner={landingBanner}
        fallbackImage={fallbackImage}
      />
    );
  }

  return <></>;
};
