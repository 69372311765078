import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';
import { noTo_font, causten_font } from '@styles/typography';
import { mq_min } from '@styles/breakpoints';
import { LandingPageBannerTypeProps } from '@shared/components/landing-page-banner/landing-page-banner.interface';
import { ImageComponent } from '@shared/components';

export const LandingPageBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${mq_min('lg')} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: ${getStyleSizes(80)};
  }
`;

export const TextContent = styled.div<LandingPageBannerTypeProps>`
  margin-top: ${getStyleSizes(30)};
  order: ${({ isArticle }) => (isArticle ? 1 : 0)};
  ${mq_min('lg')} {
    order: 0;
    padding-right: ${getStyleSizes(80)};
    flex: 1 1 ${getStyleSizes(450)};
  }
`;

export const Heading = styled.div`
  font-family: ${causten_font};
  font-weight: 400;
  font-size: ${getStyleSizes(26)};
  line-height: 1.25;
  letter-spacing: 0.01em;
  margin-top: ${getStyleSizes(8)};
  ${mq_min('lg')} {
    font-size: ${getStyleSizes(36)};
  }
`;

export const Date = styled.time`
  font-weight: 350;
  font-size: ${getStyleSizes(12)};
  line-height: ${getStyleSizes(22)};
  letter-spacing: 0.01em;
  margin-top: ${getStyleSizes(22)};
`;

export const Picture = styled.div<LandingPageBannerTypeProps>`
  order: ${({ isArticle }) => (isArticle ? 0 : 1)};
  margin: ${({ isArticle }) => getStyleSizes(isArticle ? [0, -20] : [35, -20])};

  ${mq_min('lg')} {
    width: ${getStyleSizes(719)};
    height: ${getStyleSizes(385)};
    border-radius: ${getStyleSizes(4)};
    overflow: hidden;
    box-shadow: ${getStyleSizes([3, 2, 15, 3])} rgb(0 0 0 / 10%);
    order: ${({ isArticle }) => (isArticle ? 1 : 0)};
    margin: ${({ isArticle }) => getStyleSizes(isArticle ? 0 : [0, -20])};
  }

  & img {
    height: 100%;
    width: 100%;
    max-height: ${getStyleSizes(224)};
    object-position: center;
    object-fit: cover;
    ${mq_min('md')} {
      max-height: ${getStyleSizes(350)};
    }
    ${mq_min('lg')} {
      max-height: none;
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  margin-top: ${getStyleSizes(20)};
  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(40)};
  }
`;

export const Image = styled(ImageComponent)``;
